import React, { useContext, useEffect, useRef } from 'react';
// UI
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
// import Formula1Hero from '../assets/heros/Formula1.png';
//import BannerF1 from '../assets/formula1/f1-banner.jpg';
import icono1 from '../assets/formula1/benefitsIcons/icono1.svg';
import icono2 from '../assets/formula1/benefitsIcons/icono2.svg';
import icono3 from '../assets/formula1/benefitsIcons/icono3.svg';
import icono4 from '../assets/formula1/benefitsIcons/icono4.svg';
import icono5 from '../assets/formula1/benefitsIcons/icono5.svg';
import calendarIcon from '../assets/formula1/calendar-icon.svg';
import ubiIcon from '../assets/formula1/ubi-icon.svg';
import bandera from '../assets/formula1/bandera.svg';
//import Formula1HeroMobile from '../assets/formula1/banner-mobile.png';
// import LowFormula1Hero from '../assets/heros/LowFormula1.png';
// import LowFormula1HeroMobile from '../assets/heros/LowFormula1Mobile.png';
// // import CIEandOasisImage from '../assets/formula1/CIEandOasis.png';
// import box01 from '../assets/formula1/box/box01.png';
// Components & Utils
import ViewContainer from '../components/shared/ViewContainer';
//import ImageLazyLoad from '../components/shared/ImageLazyLoad';
import LargeButton from '../components/shared/buttons/LargeButton';
import DownloadAppSection from '../components/shared/DownloadAppSection';
import FAQSection from '../components/shared/FAQSection';
import Carousel from '../components/carousel/Carousel';
import VenueItem from '../components/carousel/VenueItem';
import ContactForm from '../components/shared/ContactForm';
import HalfCircle from '../components/shared/HalfCircle';
import useElementOnScreen from '../hooks/useElementOnScreen';
import { f1BoxImages } from '../utils/formula1Data';
// import { openExternalLink, scrollIntoRef } from '../utils/util';
import { UIContext } from '../providers/UIProvider';
// import ImageWithTextCard from '../components/shared/ImageWithTextCard';
import { getResponsiveObjectForFormula1 } from '../utils/carouselResponsive';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { useNavigate } from 'react-router-dom';
import { contactData } from '../utils/contactData';
import BackgroundImage from '../assets/formula1/back-f1.png';
import BackgroundImageOasis from '../assets/formula1/back-oasis.png';

const defaultElementOnScreenOptions = {
  rootMargin: '0px',
  threshold: 0.95,
};

/**
 * @function Formula1
 * @author RCH010
 * @version 1.0
 * @description Formula1 view
 * This view contains promotional content for Formula1 GrandSandClub
 * Has a picture with text and a CTA, a grid with all the benefits, a carousel
 * with pictures of the box, the contact form, FAQ and downloadApp sections
 * @returns {JSX}
 */
const Formula1 = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  useDocumentTitle('Oasis App - GP CDMX 2025');
  const contactFormRef = useRef();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isTabletLg = useMediaQuery(theme.breakpoints.down('lg'));
  const { setNavbarBackgroundColor } = useContext(UIContext);
  const [bannerRef, isBannerRefVisible] = useElementOnScreen(
    defaultElementOnScreenOptions
  );
  const responsiveCarouselBreakpoints = getResponsiveObjectForFormula1(theme);

  useEffect(() => {
    if (!isBannerRefVisible) {
      setNavbarBackgroundColor(theme.palette.lightBackground);
    } else {
      setNavbarBackgroundColor('transparent');
    }
  }, [
    isBannerRefVisible,
    setNavbarBackgroundColor,
    theme.palette.lightBackground,
  ]);

  return (
    <ViewContainer>
      <Box ref={bannerRef} sx={{
        minHeight: isMobile ? '22em' : '25em',
        height: isMobile ? '70vh' : '70vh',
        width: '100%',
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'scroll',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        position: 'relative',
        zIndex: 1,
        marginTop: '4.5em'
      }}>
        <Box sx={{
          textAlign: 'center',
          py: 1,
          minHeight: isMobile ? '22em' : '25em',
          height: isMobile ? '70vh' : '70vh',
          maxWidth: '100%',
          mx: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Box>
            <Typography variant='h1' textAlign='center' fontSize={{xs: '40px', sm: '50px', md: '60px', lg: '80px'}} mb='1em'>
            Gran Premio de la <br /> <b style={{color: '#26d07c'}}>Ciudad de México 2025</b>
            </Typography>
            <LargeButton
              children='Reservar ahora'
              onClick={() => navigate('/events/2570/gran-premio-de-mexico-2025-formula-1')}
            />
          </Box>
        </Box>
      </Box>
      <HalfCircle isLeftCircle left='-2em' top={isMobile ? '70vh' : '70em'} />
      <HalfCircle right='-2em' bottom='80em' opacity='.6' />
      <Box
        sx={{
          zIndex: 1,
          position: 'relative',
          maxWidth: (currTheme) => currTheme.breakpoints.values.lg,
          mx: 'auto',
          mt: { xs: 10, md: 15 },
          px: 3,
        }}>
        <Typography
          variant='h2'
          textAlign='center'
          sx={{ mb: 2, fontSize: { xs: '2rem', md: '3.5rem' } }}>
          El evento más esperado del año <br /> de manera <b style={{color: '#26d07c'}}>exclusiva</b> y <b style={{color: '#26d07c'}}>segura</b>
        </Typography>

        <Stack>
          <Typography variant='p' textAlign='center' fontFamily='MuseoModerno' fontSize='25px !important' sx={{ mt: 4, mb: 6 }}>
            <p>Tenemos el lugar que estás buscando, vive una experiencia inolvidable desde Paddock Club, Skyboxes, Main Grand Stand, Foro Sol y más</p>
          </Typography>
        </Stack>
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          spacing={5}>
          <Grid item xs={12} md={6} textAlign='center'>
            <Typography variant='p' fontFamily='MuseoModerno' fontSize='25px'>
              <img src={calendarIcon} alt="Icono" style={{width: isMobile ? '9%' : '7%', verticalAlign: 'middle'}} /> 24 - 26 de Octubre
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} textAlign='center'>
            <Typography variant='p' fontFamily='MuseoModerno' fontSize='25px'>
              <img src={ubiIcon} alt="Icono" style={{width: isMobile ? '9%' : '7%', verticalAlign: 'middle'}} /> Autódromo Hermanos Rodríguez
            </Typography>
          </Grid>
        </Grid>

        {/* <Typography variant='h2' align='center' sx={{ mt: 15 }}></Typography>
        <Carousel
          carouselItems={f1BoxImages}
          Item={(props) => <VenueItem {...props} elementHeight='15em' />}
          responsive={responsiveCarouselBreakpoints}
        />*/}

        <Typography variant='h2' align='center' sx={{ mt: 15 }}>
          Beneficios de comprar tus accesos con Oasis
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            mb: 4,
            '& img': { maxWidth: '8em' },
          }}>
          <Grid
            sx={{ mt: 2, pb: 4 }}
            container
            justifyContent='center'
            alignItems='center'
            spacing={5}>
            <Grid item xs={12} md={4}>
              <Stack
                alignItems='center'
                justifyContent='flex-start'
                sx={{
                  textAlign: 'center',
                  alignSelf: 'stretch',
                  px: { xs: 0, md: 6 },
                }}>
                <img src={icono1} alt='' />
                <b style={{fontFamily: 'MuseoModerno', fontSize:'22px'}}>Plan de pagos y facturacion corporativa</b>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack
                alignItems='center'
                justifyContent='flex-start'
                sx={{
                  textAlign: 'center',
                  alignSelf: 'stretch',
                  px: { xs: 0, md: 6 },
                }}>
                <img src={icono2} alt='' />
                <b style={{fontFamily: 'MuseoModerno', fontSize:'22px'}}>Accesos 100% asegurados</b>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack
                alignItems='center'
                justifyContent='flex-start'
                sx={{ textAlign: 'center', alignSelf: 'stretch' }}>
                <img src={icono3} alt='' />
                <b style={{fontFamily: 'MuseoModerno', fontSize:'22px'}}>Seguridad Oasis antifraudes</b>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack
                alignItems='center'
                justifyContent='flex-start'
                sx={{ textAlign: 'center', alignSelf: 'stretch' }}>
                <img src={icono4} alt='' />
                <b style={{fontFamily: 'MuseoModerno', fontSize:'22px'}}>Atención personalizada 24/7</b>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack
                alignItems='center'
                justifyContent='flex-start'
                sx={{ textAlign: 'center', alignSelf: 'stretch' }}>
                <img src={icono5} alt='' />
                <b style={{fontFamily: 'MuseoModerno', fontSize:'22px'}}>Exclusiidad y privacidad</b>
              </Stack>
            </Grid>
          </Grid>
        </Box>

        <Stack
          sx={{ maxWidth: 'sm', gap: 4, mx: 'auto', mt: 2 }}
          alignItems='center'>
          <Button
            onClick={() => navigate('/events/2570/gran-premio-de-mexico-2025-formula-1')}
            size='large'
            color='secondary'
            aria-label='Compra tus boletos'
            fullWidth
            variant='contained'
            sx={{
              px: '2.5em',
              boxShadow: '2px 3px 20px rgba(41, 237, 135, 0.3)',
            }}>
            Reserva ahora
          </Button>
        </Stack>
      </Box>
      <Box sx={{
        minHeight: isMobile ? '40em' : '100%',
        height: isMobile ? '75em' : isTablet ? '135vh' : isTabletLg ? '100vh' : '100vh',
        width: '100%',
        backgroundImage: `url(${BackgroundImageOasis})`,
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'scroll',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        position: 'relative',
        zIndex: 1,
        margin: '5em 0',
        padding: isMobile ? '1em 1em' : '2em 7em'
      }}>
        <Grid
          sx={{ mt: 2, pb: 4 }}
          container
          justifyContent='center'
          alignItems='center'
          spacing={5}>
          <Grid item xs={12} md={12} lg={4} textAlign='center'>
            <Carousel
              carouselItems={f1BoxImages}
              autoPlaySpeed='5000'
              Item={(props) => <VenueItem {...props} elementHeight='30em' widthImg={isMobile ? '80%' : '100%'} />}
              responsive={responsiveCarouselBreakpoints}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={8}>
            <Typography variant='p' display='block' fontSize={{xs: '30px', sm: '35px' ,md: '50px'}} fontFamily='MuseoModerno'>
              <b style={{color: '#26d07c'}}>Oasis</b>, la empresa líder en el mercado de <b style={{color: '#26d07c'}}>renta de palcos</b> y activos de <b style={{color: '#26d07c'}}>entretenimiento.</b>
            </Typography>
            <Typography variant='p' display='block' fontSize={{xs: '20px', sm: '25px' ,md: '30px'}} marginTop='2em' fontFamily='MuseoModerno'>
                Únete y asegura tu lugar en el Gran Premio de Fórmula 1 en Ciudad de México, donde la emoción se vive al límite.
            </Typography>
            <Stack
              alignItems='center'
              justifyContent='flex-start'
              marginTop='3em'
              sx={{ textAlign: 'center', alignSelf: 'stretch' }}>
              <img src={icono5} alt='Icono' style={{width: isMobile ? '30%' : isTablet ? '30%' : '12%'}} />
              <b style={{fontFamily: 'MuseoModerno', fontSize: isMobile ? '30px' : isTablet ? '30px' : '22px'}}>Exclusividad y privacidad</b>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 10, zIndex: 1, position: 'relative' }}>
        <Box textAlign='center'>
          <Typography variant='h2' align='center' sx={{ mt: 6, mb: 6 }}>
            ¿Tienes alguna duda?
          </Typography>
          <Button
            size='large'
            color='secondary'
            aria-label='Contacta a un asesor Oasis'
            sx={{ cursor: 'pointer' }}
            variant='outlined'
            onClick={() => {
              window.open(contactData.whatsapp.href, '_blank');
            }}>
            Contacta a un asesor Oasis
          </Button>
        </Box>
        <Box textAlign='center'>
          <img src={bandera} alt="Bandera Oasis" style={{ width: isMobile ? '30%' : isTablet ? '20%' : '15%' }} />
        </Box>
        <span ref={contactFormRef} />
        <ContactForm noBackground />
      </Box>
      <DownloadAppSection />
      <FAQSection />
    </ViewContainer>
  );
};

export default Formula1;
